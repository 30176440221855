import {createPortal} from 'react-dom'
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import {CSSTransition} from 'react-transition-group'
import CircularProgress from '@material-ui/core/CircularProgress'

import {THEME} from '../../styles/muiTheme'
import './Loader.scss'

const root = document.getElementById('root')

const classNames = {
  enter: 'LoaderEnter',
  enterActive: 'LoaderEnterActive',
  exit: 'LoaderExit',
  exitActive: 'LoaderExitActive',
}

export default class Loader extends Component {
  static propTypes = {
    isFetch: PropTypes.bool,
    isBlock: PropTypes.bool,
  }

  render() {
    const {isFetch, isBlock} = this.props
    const loader = (
      <CSSTransition in={isFetch} classNames={classNames} timeout={THEME.SHORTEST} unmountOnExit>
        <div className={cn('Loader', {Loader_block: isBlock})}>
          <CircularProgress />
        </div>
      </CSSTransition>
    )
    return isBlock ? loader : createPortal(loader, root)
  }
}
