import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import {makeStyles} from '@material-ui/core/styles'
import CheckCircleIcon from '@material-ui/icons/CheckCircleRounded'
import ErrorIcon from '@material-ui/icons/ErrorRounded'
import CloseIcon from '@material-ui/icons/CloseRounded'
import IconButton from '@material-ui/core/IconButton'
import Snackbar from '@material-ui/core/Snackbar'

import {THEME} from '../../styles/muiTheme'

const anchorOrigin = {
  vertical: 'top',
  horizontal: 'right',
}

const useStyles = makeStyles({
  success: {
    backgroundColor: THEME.SUCCESS,
  },
  error: {
    backgroundColor: THEME.ERROR,
  },
  icon: {
    fontSize: THEME.FS_MD,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: 8,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
})

Notification.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  onClose: PropTypes.func.isRequired,
  variant: PropTypes.string.isRequired,
}

export default function Notification({message, onClose, variant, ...props}) {
  const classes = useStyles()
  const Icon = variant === 'success' ? CheckCircleIcon : ErrorIcon
  return (
    <Snackbar
      anchorOrigin={anchorOrigin}
      autoHideDuration={6000}
      ContentProps={{
        className: cn(classes[variant]),
      }}
      message={
        <span className={classes.message}>
          <Icon className={cn(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton key="close" color="inherit" className={classes.close} onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      onClose={onClose}
      {...props}
    />
  )
}
