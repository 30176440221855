import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'

import ScrollAnimation from '../_common/ScrollAnimation'
import Loader from '../_common/Loader'
import SearchForm from '../_common/SearchForm'
import Request from './Request'
import RequestsTable from './RequestsTable'
import {INITIAL_REQUESTS_STATE} from '../../utils/constants'
import {THEME} from '../../styles/muiTheme'

export default class Requests extends Component {
  state = {
    ...INITIAL_REQUESTS_STATE,

    selectedRow: null,
    isOpen: false,
    isRequestStatusEdit: false,
  }

  static propTypes = {
    requests: PropTypes.object,
    isRequestsFetch: PropTypes.bool.isRequired,
    statuses: PropTypes.array,
    isStatusesFetch: PropTypes.bool.isRequired,

    onRequestsFetch: PropTypes.func.isRequired,
    onStatusesFetch: PropTypes.func.isRequired,
    onErrorSnackbarOpen: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const {statuses, onRequestsFetch, onStatusesFetch} = this.props
    onRequestsFetch()
    !statuses && onStatusesFetch()
  }

  componentDidUpdate(prevProps, prevState) {
    const {onRequestsFetch} = this.props
    const {size, page, sort, search} = this.state
    if (prevState.size !== size || prevState.page !== page || prevState.sort !== sort) {
      onRequestsFetch(size, page, sort, search)
    }
    if (search !== prevState.search) {
      onRequestsFetch(size, page, sort, search)
    }
  }

  handleSetSize = (size) => this.setState({size})

  handleSetPage = (page) => this.setState({page})

  handleSetSort = (sort) => this.setState({sort})

  handleSetSearch = (search) => this.setState({search})

  handleRowSelect = (selectedRow) => () => this.setState({selectedRow, isOpen: true})

  handleDialogClose = () => this.setState({isOpen: false})

  handleRequestStatusEditSet = (isRequestStatusEdit) => this.setState({isRequestStatusEdit})

  render() {
    const {requests, isRequestsFetch, statuses, isStatusesFetch, onRequestsFetch, onErrorSnackbarOpen} = this.props
    const {size, page, sort, search, isOpen, selectedRow, isRequestStatusEdit} = this.state
    return (
      <>
        <Loader isFetch={isRequestStatusEdit} />
        <section>
          <h2 className="VisuallyHidden">Requests</h2>
          <ScrollAnimation effect="fadeIn" duration={THEME.MEDIUM}>
            <SearchForm search={search} isFetching={isRequestsFetch} onSetSearch={this.handleSetSearch} />
            <RequestsTable
              requests={requests}
              isRequestsFetch={isRequestsFetch}
              size={size}
              page={page}
              sort={sort}
              onSetSize={this.handleSetSize}
              onSetPage={this.handleSetPage}
              onSetSort={this.handleSetSort}
              onRowSelect={this.handleRowSelect}
            />
          </ScrollAnimation>
        </section>
        <Dialog open={isOpen} scroll="body" onClose={this.handleDialogClose}>
          <Request
            isOpen={isOpen}
            selectedRow={selectedRow}
            statuses={statuses}
            isStatusesFetch={isStatusesFetch}
            onRequestsFetch={onRequestsFetch}
            onRequestStatusEditSet={this.handleRequestStatusEditSet}
            onDialogClose={this.handleDialogClose}
            onErrorSnackbarOpen={onErrorSnackbarOpen}
          />
        </Dialog>
      </>
    )
  }
}
