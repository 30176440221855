import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import format from 'date-fns/format'
import {styled} from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Avatar from '@material-ui/core/Avatar'
import CircularProgress from '@material-ui/core/CircularProgress'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import PersonIcon from '@material-ui/icons/PersonRounded'

import {kc, BASE_KC_URL} from '../../index'
import {getDateFromRequest} from '../../utils/helpers'
import {THEME} from '../../styles/muiTheme'
import './Request.scss'

const StyledAvatar = styled(Avatar)({
  border: `2px solid ${THEME.PRIMARY}`,
  backgroundColor: 'transparent',
  color: THEME.PRIMARY,
  width: 60,
  height: 60,
})

const StyledButton = styled(Button)({
  marginBottom: 16,
})

const StyledPersonIcon = styled(PersonIcon)({
  width: 36,
  height: 36,
})

export default class Request extends Component {
  state = {
    status: this.props.statuses.find(item => item.status === this.props.selectedRow.requestStatus.status).id,
    comment:
      !this.props.selectedRow.comment || this.props.selectedRow.comment === '-' ? '' : this.props.selectedRow.comment,
    user: null,
    isUserFetch: true,
  }

  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    selectedRow: PropTypes.object,
    statuses: PropTypes.array,
    isStatusesFetch: PropTypes.bool.isRequired,

    onRequestsFetch: PropTypes.func.isRequired,
    onRequestStatusEditSet: PropTypes.func.isRequired,
    onDialogClose: PropTypes.func.isRequired,
    onErrorSnackbarOpen: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const {selectedRow, onErrorSnackbarOpen} = this.props
    axios
      .get(`${BASE_KC_URL}/uls-request/user/${selectedRow.userId}`)
      .then(({data}) => {
        this.setState({user: data, isUserFetch: false})
      })
      .catch(error => {
        if (error.response && error.response.status === 409) {
          this.setState({user: 'deleted', isUserFetch: false})
        }
        onErrorSnackbarOpen(error)
        this.setState({isUserFetch: false})
      })
  }

  handleRequestStatusChange = () => {
    const {selectedRow, onRequestStatusEditSet, onRequestsFetch, onDialogClose, onErrorSnackbarOpen} = this.props
    const {status, comment} = this.state
    onRequestStatusEditSet(true)
    axios
      .post(`${BASE_KC_URL}/uls-request/request-journal/${selectedRow.id}/status/${status}`, {comment})
      .then(() => {
        onRequestStatusEditSet(false)
        onRequestsFetch()
        onDialogClose()
      })
      .catch(error => {
        onRequestStatusEditSet(false)
        onErrorSnackbarOpen(error)
      })
  }

  handleStatusChange = e => this.setState({status: e.target.value})

  handleCommentChange = e => this.setState({comment: e.target.value})

  get statuses() {
    const {statuses} = this.props
    return statuses
      ? statuses.map(status => (
          <MenuItem key={status.id} value={status.id}>
            {status.status}
          </MenuItem>
        ))
      : []
  }

  get categories() {
    return this.state.user.category.map(item => <li key={item.id}>{item.name}</li>)
  }

  get roles() {
    const {user} = this.state
    return Object.keys(user.roles).map(key => (
      <TableRow key={key}>
        <TableCell>{key}</TableCell>
        <TableCell>
          <ul className="Request-List">
            {user.roles[key].map(role => (
              <li key={role.id}>{role.name}</li>
            ))}
          </ul>
        </TableCell>
      </TableRow>
    ))
  }

  render() {
    const {isOpen, selectedRow, statuses, onDialogClose} = this.props
    const {status, comment, isRequestStatusEdit, user, isUserFetch} = this.state
    const isInitialStatus = statuses.find(item => item.status === selectedRow.requestStatus.status).id === status
    return (
      <Fragment>
        <DialogTitle>
          {selectedRow.requestTypeDescription} в "<span className="FirstUppercase">{selectedRow.clientId}</span>"{' '}
          <span className="Grey">{format(getDateFromRequest(selectedRow.requestDate), 'dd.MM.yyyy, HH:mm:ss')}</span>
        </DialogTitle>
        <DialogContent>
          <div className="Request">
            <fieldset className="Request-Fieldset">
              <legend>Пользователь</legend>
              {isUserFetch || !user ? (
                <div className="LoaderContent">
                  <CircularProgress color="primary" />
                </div>
              ) : user === 'deleted' ? (
                <p>Пользователь удален</p>
              ) : (
                <Fragment>
                  <div className="Request-User">
                    <StyledAvatar>
                      <StyledPersonIcon />
                    </StyledAvatar>
                    <div>
                      <p>
                        <span className="Request-Label">Логин:</span> {user.login}
                      </p>
                      <p>
                        <span className="Request-Label">ФИО:</span> {user.firstName} {user.middleName} {user.lastName}
                      </p>
                      <p>
                        <span className="Request-Label">Email:</span> {user.email}
                      </p>
                    </div>
                  </div>
                  <StyledButton
                    size="small"
                    variant="outlined"
                    color="primary"
                    href={`${kc.authServerUrl.replace('/auth', '')}${user.userDetailLink}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Ссылка на аккаунт
                  </StyledButton>
                  <div>
                    <ExpansionPanel>
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        Категории пользователя
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <ul className="Request-List">{this.categories}</ul>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>Роли в системах</ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell>Система</TableCell>
                              <TableCell>Роли</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>{this.roles}</TableBody>
                        </Table>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </div>
                </Fragment>
              )}
            </fieldset>
            <TextField
              fullWidth
              select
              label="Статус"
              value={status}
              margin="normal"
              variant="outlined"
              onChange={this.handleStatusChange}
            >
              {this.statuses}
            </TextField>
            <TextField
              fullWidth
              multiline
              label="Комментарий для пользователя"
              rowsMax="4"
              value={comment}
              margin="normal"
              variant="outlined"
              onChange={this.handleCommentChange}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onDialogClose}>
            Закрыть
          </Button>
          <Button
            autoFocus
            color="primary"
            variant="contained"
            onClick={this.handleRequestStatusChange}
            disabled={!statuses || isRequestStatusEdit || !isOpen || isInitialStatus}
          >
            Сохранить
          </Button>
        </DialogActions>
      </Fragment>
    )
  }
}
