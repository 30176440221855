import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import TextField from '@material-ui/core/TextField'
import Fab from '@material-ui/core/Fab'
import SearchIcon from '@material-ui/icons/Search'

import Loader from './Loader'

const StyledTextField = withStyles({
  root: {
    width: 250,
  },
})(TextField)

const StyledFab = withStyles({
  root: {
    marginLeft: 8,
    flexShrink: 0,
  },
})(Fab)

export default class SearchForm extends Component {
  state = {
    value: '',
  }

  static propTypes = {
    search: PropTypes.string,
    isFetching: PropTypes.bool.isRequired,

    onSetSearch: PropTypes.func.isRequired,
  }

  handleFormSubmit = e => {
    e.preventDefault()
    this.props.onSetSearch(this.state.value)
  }

  handleInputChange = ({target: {value}}) => {
    if (!value) {
      this.props.onSetSearch('')
    }
    this.setState({value})
  }

  render() {
    const {isFetching, isPartnerFetching} = this.props
    const {value} = this.state
    return (
      <form noValidate className="SearchForm" autoComplete="off" onSubmit={this.handleFormSubmit}>
        <Loader isFetch={isPartnerFetching} />
        <StyledTextField
          fullWidth
          margin="normal"
          type="search"
          placeholder="Поиск"
          value={value}
          onChange={this.handleInputChange}
        />
        <Tooltip disableFocusListener title="Найти" enterDelay={500}>
          <div>
            <StyledFab type="submit" size="small" color="primary" disabled={!value || isFetching}>
              <SearchIcon />
            </StyledFab>
          </div>
        </Tooltip>
      </form>
    )
  }
}
