import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import {format} from 'date-fns'
import withStyles from '@material-ui/core/styles/withStyles'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import EditIcon from '@material-ui/icons/EditRounded'

import ExtendedTable from '../_common/ExtendedTable'
import {getDateFromRequest} from '../../utils/helpers'
import {THEME} from '../../styles/muiTheme'

const headerCells = [
  {title: 'Дата', sortName: 'Date'},
  {title: 'Тип', sortName: 'Type'},
  {title: 'Система', sortName: 'ClientId'},
  {title: 'Логин', sortName: 'UserLogin'},
  {title: 'Комментарий', sortName: 'Comment'},
  {title: 'Статус', sortName: 'Status'},
  {title: ''},
]

const StyledIconButton = withStyles({
  root: {
    color: THEME.PRIMARY,
  },
})(IconButton)

export default class RequestsTable extends PureComponent {
  static propTypes = {
    requests: PropTypes.object,
    isRequestsFetch: PropTypes.bool.isRequired,
    size: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    sort: PropTypes.array,

    onSetSize: PropTypes.func.isRequired,
    onSetPage: PropTypes.func.isRequired,
    onSetSort: PropTypes.func.isRequired,
    onRowSelect: PropTypes.func.isRequired,
  }

  get tableRows() {
    const {requests, onRowSelect} = this.props
    return (
      requests &&
      requests.content.map(request => ({
        id: request.id,
        data: [
          format(getDateFromRequest(request.requestDate), 'dd.MM.yyyy, hh:mm:ss'),
          request.requestTypeDescription,
          request.clientId,
          request.userLogin,
          request.description,
          request.requestStatus.status,
          <Tooltip disableFocusListener title="Изменить" enterDelay={500}>
            <StyledIconButton onClick={onRowSelect(request)}>
              <EditIcon />
            </StyledIconButton>
          </Tooltip>,
        ],
      }))
    )
  }

  render() {
    const {requests, isRequestsFetch, size, page, sort, onSetSize, onSetPage, onSetSort} = this.props
    const total = requests ? requests.totalElements : 0
    return (
      <ExtendedTable
        headerCells={headerCells}
        tableRows={this.tableRows}
        isFetching={isRequestsFetch}
        total={total}
        rowsPerPage={size}
        page={page}
        sort={sort}
        setPage={onSetPage}
        setSize={onSetSize}
        setSort={onSetSort}
      />
    )
  }
}
