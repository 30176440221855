import React, {Component} from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import {withStyles} from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TablePagination from '@material-ui/core/TablePagination'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'

import './ExtendedTable.scss'
import Loader from './Loader'
import {THEME} from '../../styles/muiTheme'

const rowsPerPageOptions = [5, 10, 25, 50]
const getLabelDisplayedRows = ({from, to, count}) => `${from}-${to} из ${count}`

const styles = {
  paper: {
    position: 'relative',
    overflowX: 'auto',
  },
  paperInternal: {
    boxShadow: 'none',
    border: `1px solid ${THEME.SECONDARY}`,
    overflow: 'hidden',
  },
  arrowDownwardIcon: {
    marginLeft: 8,
    transition: `transform ${THEME.SHORTER}ms ${THEME.EASE_IN_OUT}`,
    opacity: 0.2,
    fontSize: 18,
  },
  arrowDownwardIconRotated: {
    transform: 'rotate(-180deg)',
  },
  arrowDownwardIconActive: {
    opacity: 1,
  },
  tableRowHeadTop: {
    backgroundColor: '#fff',
  },
  tableCellCenter: {
    textAlign: 'center',
  },
  tableCellEmpty: {
    textAlign: 'center',
  },
  tableCellFont: {
    fontSize: '0.8125rem',
  },
  tableActionsCell: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}

class ExtendedTable extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    headerTopCells: PropTypes.array,
    headerCells: PropTypes.array.isRequired,
    tableRows: PropTypes.array,
    selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isFetching: PropTypes.bool,
    total: PropTypes.number,
    rowsPerPage: PropTypes.number,
    page: PropTypes.number,
    sort: PropTypes.array,
    style: PropTypes.object,
    setPage: PropTypes.func,
    setSize: PropTypes.func,
    setSort: PropTypes.func,
    onRowClick: PropTypes.func,
  }

  handleRowsPerPageChange = ({target}) => this.props.setSize(target.value)

  handlePageChange = (e, page) => this.props.setPage(page)

  handleSortChange = (sortName) => () => {
    const {sort, setSort} = this.props
    if (sort && sort[0] !== sortName) {
      setSort([sortName, 'Asc'])
    } else if (sort && sort[1] === 'Desc') {
      setSort(null)
    } else {
      setSort([sortName, sort && sort[1] === 'Asc' ? 'Desc' : 'Asc'])
    }
  }

  renderHeaderCells(headerCells) {
    const {classes, sort, isFetching, setSort} = this.props
    return headerCells.map((cell, index) => (
      <TableCell
        key={index}
        className={cn(classes.tableCellFont, {[classes.tableCellCenter]: cell.colSpan})}
        colSpan={cell.colSpan}
      >
        {cell.sortName ? (
          <button
            className={cn('ExtendedTable-Link ExtendedTable-Link_cell', {
              'ExtendedTable-Link_active': sort && sort[0] === cell.sortName,
            })}
            onClick={this.handleSortChange(cell.sortName, sort, setSort)}
            disabled={isFetching}
          >
            {cell.title}
            <ArrowDownwardIcon
              className={cn(classes.arrowDownwardIcon, {
                [classes.arrowDownwardIconRotated]: sort && sort[0] === cell.sortName && sort[1] === 'Asc',
                [classes.arrowDownwardIconActive]: sort && sort[0] === cell.sortName && sort[1],
              })}
            />
          </button>
        ) : (
          cell.title
        )}
      </TableCell>
    ))
  }

  get tableRows() {
    const {classes, headerCells, tableRows, selected, onRowClick} = this.props
    return tableRows && tableRows.length ? (
      tableRows.map((row) => (
        <TableRow
          key={row.id}
          hover={Boolean(onRowClick)}
          selected={selected === row.id}
          onClick={onRowClick ? onRowClick(row.id) : null}
        >
          {row.data.map((cell, index) => (
            <TableCell key={index} className={cn({[classes.tableActionsCell]: index === row.data.length - 1})}>
              {cell !== null && cell !== undefined && cell !== '' ? cell : '-'}
            </TableCell>
          ))}
        </TableRow>
      ))
    ) : (
      <TableRow>
        <TableCell className={classes.tableCellEmpty} colSpan={headerCells.length}>
          Нет данных
        </TableCell>
      </TableRow>
    )
  }

  render() {
    const {classes, headerCells, headerTopCells, isFetching, total, rowsPerPage, page, style} = this.props
    return (
      <Paper className={cn(`ExtendedTable ${classes.paper}`)} style={style}>
        <Loader isBlock isFetch={isFetching} />
        <div className={cn('extended-table__wrapper')}>
          <Table>
            <TableHead>
              {headerTopCells && (
                <TableRow className={classes.tableRowHeadTop}>{this.renderHeaderCells(headerTopCells)}</TableRow>
              )}
              <TableRow>{this.renderHeaderCells(headerCells)}</TableRow>
            </TableHead>
            <TableBody>{this.tableRows}</TableBody>
          </Table>
        </div>
        {rowsPerPage && (
          <TablePagination
            component="div"
            rowsPerPageOptions={rowsPerPageOptions}
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{color: 'primary'}}
            labelRowsPerPage="Количество строк:"
            labelDisplayedRows={getLabelDisplayedRows}
            onChangeRowsPerPage={this.handleRowsPerPageChange}
            onChangePage={this.handlePageChange}
          />
        )}
      </Paper>
    )
  }
}

export default withStyles(styles)(ExtendedTable)
