import React from 'react'
import {BrowserRouter} from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline'

import ScrollToTop from '../_common/ScrollToTop'
import AppContent from '../AppContent'

function App() {
  return (
    <BrowserRouter>
      <CssBaseline />
      <ScrollToTop>
        <AppContent />
      </ScrollToTop>
    </BrowserRouter>
  )
}

export default App
