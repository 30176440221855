const ACADEMY_SYSTEM_ID = 4
const MEDIA_VAILLANT_SYSTEM_ID = 24
const MEDIA_PROTHERM_SYSTEM_ID = 25
const MDS_SYSTEM_ID = 28

export const PUBLIC_SYSTEM_IDS = [ACADEMY_SYSTEM_ID, MEDIA_VAILLANT_SYSTEM_ID, MEDIA_PROTHERM_SYSTEM_ID, MDS_SYSTEM_ID]

export const INITIAL_REQUESTS_STATE = {
  size: 10,
  page: 0,
  sort: null,
  search: '',
}
